import { HiDash } from '@/util/HiDash';

// eslint-disable-next-line import/prefer-default-export
export class FleetManagementFormDelegate {
  static transformPrematureCancellationFeeModel({ formDataFleet }) {
    const base = {
      schemaVersion: '1.0.0',
      cancellationFee: formDataFleet.fees.prematureCancellationFee.cancellationFee,
      driverShare: HiDash.zeroIfNil(formDataFleet.fees.prematureCancellationFee.driverShare), // Driver share is zero by default,
      useDispatchCancellationFees: formDataFleet.fees.prematureCancellationFee.useDispatchCancellationFees,
    };


    const isChargeablePostDriverAssignedDurationConfigEnabled = HiDash.isGreaterThanZero(formDataFleet.fees.prematureCancellationFee.chargeablePostDriverAssignedDurationMinutes);
    const isExemptDriverAssignmentDurationConfigEnabled = HiDash.isGreaterThanZero(formDataFleet.fees.prematureCancellationFee.exemptDriverAssignmentDurationMinutes);

    return {
      ...base,
      chargeablePostDriverAssignedDurationSec: isChargeablePostDriverAssignedDurationConfigEnabled ? (formDataFleet.fees.prematureCancellationFee.chargeablePostDriverAssignedDurationMinutes * 60) : null,
      exemptDriverAssignmentDurationSec: isExemptDriverAssignmentDurationConfigEnabled ? (formDataFleet.fees.prematureCancellationFee.exemptDriverAssignmentDurationMinutes * 60) : null,
    };
  }

  static transformNoShowFeeModel({ formDataFleet, localFleetModel }) {
    const base = {
      schemaVersion: '1.0.0',
      noShowFee: formDataFleet.fees.passengerNoShowFeeConfig.noShowFee,
      driverShare: HiDash.zeroIfNil(formDataFleet.fees.passengerNoShowFeeConfig.driverShare),
      useDispatchNoShowFees: formDataFleet.fees.passengerNoShowFeeConfig.useDispatchNoShowFees,
    };

    const isRequiredDriverWaitingDurationAfterArrivalEnabled = localFleetModel.fees.noShowFee.requiredDriverWaitingDurationAfterArrivalConfig.isEnabled;
    const isExemptAsapDriverArrivalDurationEnabled = localFleetModel.fees.noShowFee.exemptAsapDriverArrivalDurationConfig.isEnabled;
    const isPrebookingMaxPickupToArrivalDeltaEnabled = localFleetModel.fees.noShowFee.preBookingMaxPickupToArrivalDeltaConfig.isEnabled;

    return {
      ...base,
      requiredDriverWaitingDurationAfterArrivalSec: isRequiredDriverWaitingDurationAfterArrivalEnabled ? (HiDash.secondsToMinutes(formDataFleet.fees.passengerNoShowFeeConfig.requiredDriverWaitingDurationAfterArrivalMinutes)) : null,
      exemptAsapDriverArrivalDurationSec: isExemptAsapDriverArrivalDurationEnabled ? (HiDash.secondsToMinutes(formDataFleet.fees.passengerNoShowFeeConfig.exemptAsapDriverArrivalDurationMinutes)) : null,
      prebookingMaxPickupToArrivalDeltaSec: isPrebookingMaxPickupToArrivalDeltaEnabled ? (HiDash.secondsToMinutes(formDataFleet.fees.passengerNoShowFeeConfig.prebookingMaxPickupToArrivalDeltaMinutes)) : null,
    };
  }
}
