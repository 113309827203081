<template>
  <div class="fleet-management-form-cmp">
    <v-overlay :value="isLoading" z-index="1" opacity="0.1">
      <v-progress-circular
        indeterminate
        size="64"
        color="blue"
      ></v-progress-circular>
    </v-overlay>
    <v-row v-if="!isLoading">
      <v-col cols="12">
        <v-tabs
          color="deep-purple accent-4"
          v-model="activeTab"
        >
          <v-tab :href="'#fleet'">Fleet</v-tab>
          <v-tab :href="'#fleetBookingAppConfig'" :disabled="!isUpdateForm">
            {{ $t('fleetManagementForm.fleetBookingAppConfig.label') }}
          </v-tab>

          <v-tabs-items v-model="activeTab">
            <!-- BEGIN: fleet-tab -->
            <v-tab-item :value="'fleet'">
              <v-col>
                <v-col cols="12" class="py-14 px-8">
                  <v-row>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="humanReadableId"
                        v-model="fleet.humanReadableId"
                        :validator="$v.fleet.humanReadableId">
                        <v-text-field
                          v-model="fleet.humanReadableId"
                          :label="$t(`onboarding.humanReadableId`)"
                          :placeholder="$t('fleetManagementForm.humanReadableId')"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="locale"
                        v-model="fleet.locale"
                        :validator="$v.fleet.locale">
                        <v-text-field
                          v-model="fleet.locale"
                          :label="$t(`onboarding.locale`)"
                          :placeholder="$t('fleetManagementForm.locale')"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="supportPhoneNumber"
                        v-model="fleet.supportPhoneNumber"
                        :validator="$v.fleet.supportPhoneNumber">
                        <v-text-field
                          v-model="fleet.supportPhoneNumber"
                          :label="$t(`fleetManagementForm.supportPhoneNumber`)"
                          :placeholder="$t('fleetManagementForm.supportPhoneNumber')"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="driverConnectPhoneNumber"
                        v-model="fleet.driverConnectPhoneNumber"
                        :validator="$v.fleet.driverConnectPhoneNumber">
                        <v-text-field
                          v-model="fleet.driverConnectPhoneNumber"
                          :label="$t(`fleetManagementForm.driverConnectPhoneNumber`)"
                          :placeholder="$t('fleetManagementForm.driverConnectPhoneNumber')"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="dataRegion"
                        v-model="fleet.dataRegion"
                        :validator="$v.fleet.dataRegion">
                        <v-select
                          v-model="fleet.dataRegion"
                          :items="dataRegions"
                          :item-text="'display'"
                          :item-value="'code'"
                          :label="$t(`fleetManagementForm.dataRegion`)"
                          :placeholder="$t('fleetManagementForm.dataRegion')"
                          outlined
                          no-filter
                        ></v-select>
                      </validated-component>
                    </v-col>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="country"
                        v-model="fleet.country"
                        :validator="$v.fleet.country">
                        <v-autocomplete
                          v-model="fleet.country"
                          :items="countryCodes"
                          :label="$t(`fleetManagementForm.country`)"
                          :placeholder="$t('fleetManagementForm.country')"
                          :key="fleet.country"
                          outlined
                          flat
                        ></v-autocomplete>
                      </validated-component>
                    </v-col>
                  </v-row>
                  <!-- Start legal information toggle -->
                  <v-row>
                    <v-col cols="12" class="form-part">
                      <v-checkbox v-model="fleet.legalInformation.enabled"
                                  :label="$t(`fleetManagementForm.legalInformation`)">
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="fleet.legalInformation.enabled">
                    <v-col cols="4" class="form-part">
                      <validated-component
                        name="officialCompanyName"
                        v-model="fleet.legalInformation.officialCompanyName"
                        :validator="$v.fleet.legalInformation.officialCompanyName">
                        <v-text-field
                          v-model="fleet.legalInformation.officialCompanyName"
                          :label="$t(`fleetManagementForm.officialCompanyName`)"
                          :placeholder="$t('fleetManagementForm.officialCompanyName')"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                    <v-col cols="4" class="form-part">
                      <validated-component
                        name="companyAddress"
                        v-model="fleet.legalInformation.companyAddress"
                        :validator="$v.fleet.legalInformation.companyAddress">
                        <v-text-field
                          v-model="fleet.legalInformation.companyAddress"
                          :label="$t(`fleetManagementForm.companyAddress`)"
                          :placeholder="$t('fleetManagementForm.companyAddress')"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                    <v-col cols="4" class="form-part">
                      <validated-component
                        name="vatNumber"
                        v-model="fleet.legalInformation.vatNumber"
                        :validator="$v.fleet.legalInformation.vatNumber">
                        <v-text-field
                          v-model="fleet.legalInformation.vatNumber"
                          :label="$t(`fleetManagementForm.vatNumber`)"
                          :placeholder="$t('fleetManagementForm.vatNumber')"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                  </v-row>
                  <!-- End legal information toggle -->
                  <v-row>
                    <v-col cols="12" class="form-part">
                      <v-label>{{ $t('fleetManagementForm.payment') }}</v-label>
                      <v-alert
                        v-if="hasNetworkAssociated"
                        border="left"
                        colored-border
                        type="success"
                        elevation="1"
                      >
                        {{ $t('fleetManagementForm.paymentInstanceNotModifiable') }}
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="paymentAccountId"
                        v-model="fleet.paymentAccountId"
                        :validator="$v.fleet.paymentAccountId">
                        <v-text-field
                          v-model="fleet.paymentAccountId"
                          :label="$t(`fleetManagementForm.paymentAccountId`)"
                          :placeholder="$t('fleetManagementForm.paymentAccountId')"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="paymentFailureAccountId"
                        v-model="fleet.paymentFailureAccountId"
                        :validator="$v.fleet.paymentFailureAccountId">
                        <v-text-field
                          v-model="fleet.paymentFailureAccountId"
                          :label="$t(`fleetManagementForm.paymentFailureAccountId`)"
                          :placeholder="$t('fleetManagementForm.paymentFailureAccountId')"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="form-part">
                      <v-label>{{ $t('fleetManagementForm.paymentConnectors') }}</v-label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="form-part">
                      <v-select
                        v-model="selectedPaymentConnectorName"
                        :items="availablePaymentConnectorNames"
                        :label="$t(`fleetManagementForm.paymentProvider`)"
                        :placeholder="$t('fleetManagementForm.paymentProvider')"
                        outlined
                        no-filter
                      ></v-select>
                    </v-col>
                    <v-col cols="4" class="form-part">
                      <v-btn
                        :disabled="!selectedPaymentConnectorName"
                        min-height="55"
                        outlined
                        @click="addConnector">
                        {{ $t('fleetManagementForm.add') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-card v-for="(connector, index) in fleet.connectors.payment" :key="index"
                              class="payment-connector">
                        <v-card-text>
                          <v-row>
                            <v-col offset="11" cols="1">
                              <v-btn class="float-right" depressed outlined
                                     :disabled="!!paymentConnectorNamesDisableRemoval.includes(fleet.connectors.payment[index].name)">
                      <span @click="() => removePaymentConnector(index)">
                        <v-icon>delete</v-icon>
                      </span>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="3" class="form-part">
                              <validated-component
                                name="paymentProvider"
                                v-model="fleet.connectors.payment[index].name"
                                :validator="$v.fleet.connectors.payment.$each[index].name">
                                <v-text-field
                                  v-model="fleet.connectors.payment[index].name"
                                  :label="$t(`fleetManagementForm.paymentProvider`)"
                                  :placeholder="$t('fleetManagementForm.paymentProvider')"
                                  outlined
                                  no-filter
                                  readonly>
                                </v-text-field>
                              </validated-component>
                            </v-col>
                            <v-col cols="3" class="form-part">
                              <validated-component
                                name="paymentInstance"
                                v-model="fleet.connectors.payment[index].instance"
                                :validator="$v.fleet.connectors.payment.$each[index].instance">
                                <v-text-field
                                  readonly
                                  v-model="fleet.connectors.payment[index].instance"
                                  :label="$t(`fleetManagementForm.paymentInstance`)"
                                  :placeholder="$t('fleetManagementForm.paymentInstance')"
                                  outlined
                                  no-filter
                                ></v-text-field>
                              </validated-component>
                            </v-col>
                            <v-col cols="3" class="form-part">
                              <validated-component
                                name="paymentProviderAccountId"
                                v-model="fleet.connectors.payment[index].configuration.accountId"
                                :validator="$v.fleet.connectors.payment.$each[index].configuration.accountId">
                                <v-text-field
                                  :readonly="fleet.connectors.payment[index].deprecated"
                                  v-model="fleet.connectors.payment[index].configuration.accountId"
                                  :label="$t(`fleetManagementForm.paymentProviderAccountId`)"
                                  :placeholder="$t('fleetManagementForm.paymentProviderAccountId')"
                                  outlined
                                  hide-details
                                  no-filter
                                ></v-text-field>
                              </validated-component>
                            </v-col>
                            <v-col cols="3" class="form-part">
                              <validated-component
                                name="paymentStatementDescriptor"
                                v-model="fleet.connectors.payment[index].configuration.statementDescriptor"
                                :validator="$v.fleet.connectors.payment.$each[index].configuration.statementDescriptor">
                                <v-text-field
                                  :readonly="fleet.connectors.payment[index].deprecated"
                                  v-model="fleet.connectors.payment[index].configuration.statementDescriptor"
                                  :label="$t(`fleetManagementForm.paymentStatementDescriptor`)"
                                  :placeholder="$t('fleetManagementForm.paymentStatementDescriptor')"
                                  outlined
                                  hide-details
                                  no-filter
                                ></v-text-field>
                              </validated-component>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" class="form-part">
                              <v-label>{{ $t('fleetManagementForm.fees') }}</v-label>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6" class="form-part">
                              <validated-component
                                name="fixedFare"
                                v-model="fleet.connectors.payment[index].configuration.fees.fixed"
                                :validator="$v.fleet.connectors.payment.$each[index].configuration.fees.fixed">
                                <v-text-field
                                  :readonly="fleet.connectors.payment[index].deprecated"
                                  v-model="fleet.connectors.payment[index].configuration.fees.fixed"
                                  :label="$t(`fleetManagementForm.fixedFare`)"
                                  :placeholder="$t('fleetManagementForm.fixedFare')"
                                  outlined
                                  type="number"
                                  hide-details
                                  no-filter
                                ></v-text-field>
                              </validated-component>
                            </v-col>
                            <v-col cols="6" class="form-part">
                              <validated-component
                                name="farePercentage"
                                v-model="fleet.connectors.payment[index].configuration.fees.percentage"
                                :validator="$v.fleet.connectors.payment.$each[index].configuration.fees.percentage">
                                <v-text-field
                                  :readonly="fleet.connectors.payment[index].deprecated"
                                  v-model="fleet.connectors.payment[index].configuration.fees.percentage"
                                  :label="$t(`fleetManagementForm.farePercentage`)"
                                  :placeholder="$t('fleetManagementForm.farePercentage')"
                                  outlined
                                  type="number"
                                  hide-details
                                  no-filter
                                ></v-text-field>
                              </validated-component>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" class="form-part">
                              <v-label>{{ $t('fleetManagementForm.externalFees') }}</v-label>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" class="form-part">
                              <validated-component
                                name="fixedFare"
                                v-model="fleet.connectors.payment[index].configuration.managedFees"
                                :validator="$v.fleet.connectors.payment.$each[index].configuration.managedFees">
                                <managed-fees-form
                                  v-model="fleet.connectors.payment[index].configuration.managedFees"></managed-fees-form>
                              </validated-component>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="form-part">
                      <v-checkbox v-model="fleet.sendOnBehalfOf"
                                  :label="$t('fleetManagementForm.sendOnBehalfOf')"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="form-part">
                      <v-checkbox v-model="fleet.sendPreauthEmail"
                                  :label="$t('fleetManagementForm.sendPreauthEmail')"></v-checkbox>
                    </v-col>
                  </v-row>
                  <!-- BEGIN: fees -->
                  <v-row class="mb-6">
                    <v-col cols="12">
                      <v-card>
                        <v-card-title>
                          <h2>{{ $t('fleetManagementForm.fleetFees.label') }}</h2>
                        </v-card-title>
                        <hr class="mb-4">
                        <v-card-subtitle class="mb-4"><h2>{{
                            $t('fleetManagementForm.fleetFees.prematureCancellationFee.cancellationFee')
                          }}</h2></v-card-subtitle>
                        <v-card-text>
                          <!-- BEGIN: premature-cancellation-fee -->
                          <v-row class="mt-0" v-if="fleet.fees">
                            <v-col cols="12" class="form-part">
                              <v-row>
                                <v-col cols="12">
                                  <v-row>
                                    <v-col cols="12">
                                      <div class="d-flex flex-row align-center">
                                        <v-checkbox v-model="fleet.fees.prematureCancellationFee.useDispatchCancellationFees"
                                                    :label="$t('fleetManagementForm.fleetFees.prematureCancellationFee.useDispatchSettings')"
                                                    class="mt-0"></v-checkbox>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <template v-if="isPrematureCancellationFeeRuleSet && !fleet.fees.prematureCancellationFee.useDispatchCancellationFees">
                                <!-- Premature Cancellation: Fees Row -->
                                <v-row class="mt-6">
                                  <!-- Premature Cancellation: Fee Amount -->
                                  <v-col cols="6" class="form-part">
                                    <validated-component
                                      v-model="fleet.fees.prematureCancellationFee.cancellationFee"
                                      :validator="$v.fleet.fees.prematureCancellationFee.cancellationFee"
                                      :name="$t(`fleetManagementForm.fleetFees.prematureCancellationFee.cancellationFee`)"
                                    >
                                      <v-text-field
                                        v-model.number="fleet.fees.prematureCancellationFee.cancellationFee"
                                        :label="$t(`fleetManagementForm.fleetFees.prematureCancellationFee.cancellationFee`)"
                                        type="number"
                                        max="20"
                                        min="0"
                                        hide-details
                                        no-filter
                                        outlined
                                        class="mx-4"
                                        :suffix="fleet.currency"
                                        dense
                                      >
                                      </v-text-field>
                                    </validated-component>
                                  </v-col>

                                  <!-- Premature Cancellation: Driver Share -->
                                  <v-col cols="6" class="form-part">
                                    <validated-component
                                      v-model="fleet.fees.prematureCancellationFee.driverShare"
                                      :validator="$v.fleet.fees.prematureCancellationFee.driverShare"
                                      :name="$t(`fleetManagementForm.fleetFees.prematureCancellationFee.driverShare`)"
                                    >
                                      <v-text-field
                                        v-model.number="fleet.fees.prematureCancellationFee.driverShare"
                                        :label="$t(`fleetManagementForm.fleetFees.prematureCancellationFee.driverShare`)"
                                        type="number"
                                        max="20"
                                        min="0"
                                        hide-details
                                        no-filter
                                        outlined
                                        class="mx-4"
                                        :suffix="fleet.currency"
                                        dense
                                      ></v-text-field>
                                    </validated-component>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col cols="12">
                                    <h4>
                                      Constraints
                                    </h4>
                                    <!-- BEGIN: exemptDriverAssignmentDurationInput -->
                                    <v-row>
                                      <v-col cols="12">
                                        <div class="d-flex flex-row align-center">
                                          <v-checkbox
                                            class="ma-0 mr-8"
                                            :disabled="true"
                                            v-model="localFleetModel.fees.prematureCancellationFee.exemptDriverAssignmentDurationConfig.isEnabled"
                                          ></v-checkbox>
                                          <i18n
                                            :class="{'text--disabled': !localFleetModel.fees.prematureCancellationFee.exemptDriverAssignmentDurationConfig.isEnabled}"
                                            path="fleetManagementForm.fleetFees.prematureCancellationFee.exemptDriverAssignmentDurationInput">
                                            <template v-slot:lineBreak>
                                              <br>
                                            </template>
                                            <template v-slot:exemptDriverAssignmentDurationInput>
                                              <validated-component
                                                :name="$t(`fleetManagementForm.fleetFees.prematureCancellationFee.exemptDriverAssignmentDuration`)"
                                                v-model="fleet.fees.prematureCancellationFee.exemptDriverAssignmentDurationMinutes"
                                                :validator="$v.fleet.fees.prematureCancellationFee.exemptDriverAssignmentDurationMinutes"
                                                class="d-inline-block">
                                                <v-text-field
                                                  :disabled="!localFleetModel.fees.prematureCancellationFee.exemptDriverAssignmentDurationConfig.isEnabled"
                                                  v-model.number="fleet.fees.prematureCancellationFee.exemptDriverAssignmentDurationMinutes"
                                                  outlined
                                                  type="number"
                                                  max="20"
                                                  min="1"
                                                  hide-details
                                                  no-filter
                                                  class="mx-4"
                                                  dense
                                                ></v-text-field>
                                              </validated-component>
                                            </template>
                                          </i18n>
                                        </div>
                                        <!-- BEGIN: chargeablePostDriverAssignedDurationInput -->
                                        <div class="d-flex flex-row align-center">
                                          <v-checkbox
                                            class="ma-0 mr-8"
                                            :disabled="true"
                                            v-model="localFleetModel.fees.prematureCancellationFee.chargeablePostDriverAssignedDurationConfig.isEnabled"
                                          ></v-checkbox>
                                          <i18n
                                            :class="{'text--disabled': !localFleetModel.fees.prematureCancellationFee.chargeablePostDriverAssignedDurationConfig.isEnabled}"
                                            path="fleetManagementForm.fleetFees.prematureCancellationFee.chargeablePostDriverAssignedDurationInput">
                                            <template v-slot:lineBreak>
                                              <br>
                                            </template>
                                            <template
                                              v-slot:chargeablePostDriverAssignedDurationInput>
                                              <validated-component
                                                :name="$t(`fleetManagementForm.fleetFees.prematureCancellationFee.chargeablePostDriverAssignedDuration`)"
                                                v-model="fleet.fees.prematureCancellationFee.chargeablePostDriverAssignedDurationMinutes"
                                                :validator="$v.fleet.fees.prematureCancellationFee.chargeablePostDriverAssignedDurationMinutes"
                                                class="d-inline-block">
                                                <v-text-field
                                                  :disabled="!localFleetModel.fees.prematureCancellationFee.chargeablePostDriverAssignedDurationConfig.isEnabled"
                                                  v-model.number="fleet.fees.prematureCancellationFee.chargeablePostDriverAssignedDurationMinutes"
                                                  outlined
                                                  type="number"
                                                  max="20"
                                                  min="1"
                                                  hide-details
                                                  no-filter
                                                  class="mx-4"
                                                  dense
                                                ></v-text-field>
                                              </validated-component>
                                            </template>
                                          </i18n>
                                        </div>
                                        <!-- BEGIN: chargeablePostDriverAssignedDurationInput -->
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </template>
                              <div v-if="isPrematureCancellationFeeRuleSet && !fleet.fees.prematureCancellationFee.useDispatchCancellationFees">
                                <v-btn
                                  color="error"
                                  outlined
                                  x-small
                                  @click="removePrematureCancellationFeeRule"
                                >{{
                                    $t('fleetManagementForm.fleetFees.prematureCancellationFee.removeCancellationFeeRule')
                                  }}
                                </v-btn>
                              </div>
                              <div v-if="!isPrematureCancellationFeeRuleSet && !fleet.fees.prematureCancellationFee.useDispatchCancellationFees">
                                <v-btn
                                  color="success"
                                  outlined
                                  x-small
                                  @click="addPrematureCancellationFeeRule"
                                >{{
                                    $t('fleetManagementForm.fleetFees.prematureCancellationFee.addCancellationFeeRule')
                                  }}
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                          <!-- END: premature-cancellation-fee -->
                          <!-- BEGIN: no-show-fee -->
                          <h2 class="mt-36 mb-12">
                            {{
                              $t('fleetManagementForm.fleetFees.passengerNoShowFee.sectionTitle')
                            }}</h2>
                          <v-row v-if="fleet.fees">
                            <v-col cols="12">
                              <div class="d-flex flex-row align-center">
                                <v-checkbox v-model="fleet.fees.passengerNoShowFeeConfig.useDispatchNoShowFees"
                                            :label="$t('fleetManagementForm.fleetFees.passengerNoShowFee.useDispatchSettings')"
                                            class="mt-0"></v-checkbox>
                              </div>
                            </v-col>
                            <v-col v-if="!fleet.fees.passengerNoShowFeeConfig.useDispatchNoShowFees" cols="12">
                              <!-- No Show Fees Config -->
                              <template v-if="isPassengerNoShowFeeRuleSet">
                                <!-- No-Show: Fees Row -->
                                <v-row>
                                  <!-- No-Show: Fee Amount -->
                                  <v-col cols="6" class="form-part">
                                    <validated-component
                                      v-model="fleet.fees.passengerNoShowFeeConfig.noShowFee"
                                      :validator="$v.fleet.fees.passengerNoShowFeeConfig.noShowFee"
                                      :name="$t(`fleetManagementForm.fleetFees.passengerNoShowFee.feeAmountLabel`)"
                                    >
                                      <v-text-field
                                        v-model.number="fleet.fees.passengerNoShowFeeConfig.noShowFee"
                                        :label="$t(`fleetManagementForm.fleetFees.passengerNoShowFee.feeAmountLabel`)"
                                        type="number"
                                        max="20"
                                        min="0"
                                        hide-details
                                        no-filter
                                        outlined
                                        class="mx-4"
                                        :suffix="fleet.currency"
                                        dense
                                      >
                                      </v-text-field>
                                    </validated-component>
                                  </v-col>

                                  <!-- No-Show: Driver Share -->
                                  <v-col cols="6" class="form-part">
                                    <validated-component
                                      v-model="fleet.fees.passengerNoShowFeeConfig.driverShare"
                                      :validator="$v.fleet.fees.passengerNoShowFeeConfig.driverShare"
                                      :name="$t(`fleetManagementForm.fleetFees.passengerNoShowFee.driverShare`)"
                                    >
                                      <v-text-field
                                        v-model.number="fleet.fees.passengerNoShowFeeConfig.driverShare"
                                        :label="$t(`fleetManagementForm.fleetFees.passengerNoShowFee.driverShare`)"
                                        type="number"
                                        max="20"
                                        min="0"
                                        hide-details
                                        no-filter
                                        outlined
                                        class="mx-4"
                                        :suffix="fleet.currency"
                                        dense
                                      ></v-text-field>
                                    </validated-component>
                                  </v-col>
                                </v-row>

                                <v-row class="mt-4">
                                  <v-col cols="12">
                                    <v-alert
                                      text
                                      color="info"
                                    >
                                      <h4>
                                        Additional constraints
                                      </h4>
                                      <ul>
                                        <li>
                                          You may add additional constraints that will instruct the
                                          system to apply only when those constraints are met
                                        </li>
                                        <li>
                                          If any of the activated fail, the no show fee will
                                          <strong>not</strong> be applied
                                        </li>
                                      </ul>
                                    </v-alert>
                                  </v-col>
                                </v-row>

                                <!-- No-Show Fee: exemptAsapDriverArrivalDuration -->
                                <v-row class="m-t-0">
                                  <v-col cols="12">
                                    <!-- BEGIN: requiredDriverWaitingDurationAfterArrivalConfig -->
                                    <div class="d-flex flex-row align-center">
                                      <v-switch
                                        class="ma-0 mr-8"
                                        v-model="localFleetModel.fees.noShowFee.requiredDriverWaitingDurationAfterArrivalConfig.isEnabled"
                                      ></v-switch>
                                      <i18n
                                        :class="{'text--disabled': !localFleetModel.fees.noShowFee.requiredDriverWaitingDurationAfterArrivalConfig.isEnabled}"
                                        path="fleetManagementForm.fleetFees.passengerNoShowFee.requiredDriverWaitingDurationAfterArrivalLabel">
                                        <template v-slot:lineBreak>
                                          <br>
                                        </template>
                                        <template
                                          v-slot:requiredDriverWaitingDurationAfterArrivalInput>
                                          <validated-component
                                            v-model="fleet.fees.passengerNoShowFeeConfig.requiredDriverWaitingDurationAfterArrivalMinutes"
                                            :validator="$v.fleet.fees.passengerNoShowFeeConfig.requiredDriverWaitingDurationAfterArrivalMinutes"
                                            :name="$t(`fleetManagementForm.fleetFees.passengerNoShowFee.requiredDriverWaitingDurationAfterArrival`)"
                                            class="d-inline-block"
                                          >
                                            <v-text-field
                                              :disabled="!localFleetModel.fees.noShowFee.requiredDriverWaitingDurationAfterArrivalConfig.isEnabled"
                                              v-model.number="fleet.fees.passengerNoShowFeeConfig.requiredDriverWaitingDurationAfterArrivalMinutes"
                                              outlined
                                              type="number"
                                              max="60"
                                              min="1"
                                              hide-details
                                              no-filter
                                              class="mx-4"
                                              dense
                                            />
                                          </validated-component>
                                        </template>
                                      </i18n>
                                    </div>
                                    <!-- END: requiredDriverWaitingDurationAfterArrivalConfig -->

                                    <!-- BEGIN: exemptAsapDriverArrivalDurationConfig -->
                                    <div class="d-flex flex-row align-center">
                                      <v-switch
                                        class="ma-0 mr-8"
                                        v-model="localFleetModel.fees.noShowFee.exemptAsapDriverArrivalDurationConfig.isEnabled"
                                      ></v-switch>
                                      <i18n
                                        :class="{'text--disabled': !localFleetModel.fees.noShowFee.exemptAsapDriverArrivalDurationConfig.isEnabled}"
                                        path="fleetManagementForm.fleetFees.passengerNoShowFee.exemptAsapDriverArrivalDurationLabel">
                                        <template v-slot:lineBreak>
                                          <br>
                                        </template>
                                        <template v-slot:exemptAsapDriverArrivalDurationInput>
                                          <validated-component
                                            v-model="fleet.fees.passengerNoShowFeeConfig.exemptAsapDriverArrivalDurationMinutes"
                                            :validator="$v.fleet.fees.passengerNoShowFeeConfig.exemptAsapDriverArrivalDurationMinutes"
                                            :name="$t(`fleetManagementForm.fleetFees.passengerNoShowFee.exemptAsapDriverArrivalDuration`)"
                                            class="d-inline-block"
                                          >
                                            <v-text-field
                                              :disabled="!localFleetModel.fees.noShowFee.exemptAsapDriverArrivalDurationConfig.isEnabled"
                                              v-model.number="fleet.fees.passengerNoShowFeeConfig.exemptAsapDriverArrivalDurationMinutes"
                                              outlined
                                              type="number"
                                              max="60"
                                              min="1"
                                              hide-details
                                              no-filter
                                              class="mx-4"
                                              dense
                                            />
                                          </validated-component>
                                        </template>
                                      </i18n>
                                    </div>
                                    <!-- END: exemptAsapDriverArrivalDurationConfig -->

                                    <!-- BEGIN: preBookingMaxPickupToArrivalDeltaConfig -->
                                    <div class="d-flex flex-row align-center">
                                      <v-switch
                                        class="ma-0 mr-8"
                                        v-model="localFleetModel.fees.noShowFee.preBookingMaxPickupToArrivalDeltaConfig.isEnabled"
                                      ></v-switch>
                                      <i18n
                                        :class="{'text--disabled': !localFleetModel.fees.noShowFee.preBookingMaxPickupToArrivalDeltaConfig.isEnabled}"
                                        path="fleetManagementForm.fleetFees.passengerNoShowFee.prebookingMaxPickupToArrivalDeltaLabel">
                                        <template v-slot:lineBreak>
                                          <br>
                                        </template>
                                        <template
                                          v-slot:prebookingMaxPickupToArrivalDeltaInput
                                        >
                                          <validated-component
                                            v-model="fleet.fees.passengerNoShowFeeConfig.prebookingMaxPickupToArrivalDeltaMinutes"
                                            :validator="$v.fleet.fees.passengerNoShowFeeConfig.prebookingMaxPickupToArrivalDeltaMinutes"
                                            :name="$t(`fleetManagementForm.fleetFees.passengerNoShowFee.prebookingMaxPickupToArrivalDelta`)"
                                            class="d-inline-block"
                                          >
                                            <v-text-field
                                              :disabled="!localFleetModel.fees.noShowFee.preBookingMaxPickupToArrivalDeltaConfig.isEnabled"
                                              v-model.number="fleet.fees.passengerNoShowFeeConfig.prebookingMaxPickupToArrivalDeltaMinutes"
                                              outlined
                                              type="number"
                                              max="60"
                                              min="1"
                                              hide-details
                                              no-filter
                                              class="mx-4"
                                              dense
                                            />
                                          </validated-component>
                                        </template>
                                      </i18n>
                                    </div>
                                    <!-- END: preBookingMaxPickupToArrivalDeltaConfig -->
                                  </v-col>
                                </v-row>
                              </template>

                              <!-- No-Show: "Remove Rule" Button -->
                              <v-row
                                v-if="isPassengerNoShowFeeRuleSet"
                              >
                                <v-col cols="12">
                                  <v-btn
                                    color="error"
                                    outlined
                                    x-small
                                    @click="removePassengerNoShowFeeRule"
                                  >{{
                                      $t('fleetManagementForm.fleetFees.passengerNoShowFee.removeNoShowFeeRule')
                                    }}
                                  </v-btn>
                                </v-col>
                              </v-row>

                              <div v-if="!isPassengerNoShowFeeRuleSet">
                                <v-btn
                                  color="success"
                                  outlined
                                  x-small
                                  @click="addPassengerNoShowFeeRule"
                                >{{
                                    $t('fleetManagementForm.fleetFees.passengerNoShowFee.addNoShowFeeRule')
                                  }}
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                          <!-- END: no-show-fee -->
                          <hr class="mt-12 mb-12">

                          <!-- ROW: displayExtraTariffPrice -->
                          <v-row v-if="fleet.fees">
                            <v-col cols="12" class="form-part">
                              <v-alert
                                border="left"
                                color="green"
                                outlined
                                type="info"
                              >{{
                                  $t('fleetManagementForm.fleetFees.displayExtraTariffPrice.helpText')
                                }}
                              </v-alert>
                              <v-checkbox v-model="fleet.fees.displayExtraTariffPrice"
                                          :label="$t('fleetManagementForm.fleetFees.displayExtraTariffPrice.label')"></v-checkbox>
                            </v-col>
                          </v-row>

                          <!-- ROW: isFixedFarePreAuthMarginEnabled -->
                          <v-row v-if="fleet.fees">
                            <v-col cols="12" class="form-part">
                              <v-alert
                                border="left"
                                color="green"
                                outlined
                                type="info"
                              >{{
                                  $t('fleetManagementForm.fleetFees.isFixedFarePreAuthMarginEnabled.helpText')
                                }}
                              </v-alert>
                              <v-checkbox v-model="fleet.fees.isFixedFarePreAuthMarginEnabled"
                                          :label="$t('fleetManagementForm.fleetFees.isFixedFarePreAuthMarginEnabled.label')"></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- END: fees -->

                  <!-- BEGIN: WAITING TIME CONFIGURATION -->
                  <v-row class="mb-6">
                    <v-col cols="12">
                      <v-card>
                        <v-card-title>
                          <h2>{{ $t('fleetManagementForm.waitingTimeConfiguration.label') }}</h2>
                        </v-card-title>
                        <hr class="mb-12">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" class="form-part">
                              <template v-if="isWaitingTimeConfigurationEnabled">
                                <v-row class="mt-2 pb-0">
                                  <v-col cols="6">
                                    <validated-component
                                      name="selectionType"
                                      v-model="fleet.waitingTimeConfiguration.selectionType"
                                      :validator="$v.fleet.waitingTimeConfiguration.selectionType"
                                    >
                                      <v-radio-group v-model="fleet.waitingTimeConfiguration.selectionType">
                                        <v-radio
                                          v-for="availableWaitingTimeSelection in availableWaitingTimeSelections"
                                          :key="availableWaitingTimeSelection.value"
                                          :label=" availableWaitingTimeSelection.text"
                                          :value="availableWaitingTimeSelection.value"
                                          :class="{'mt-8 mb-0': availableWaitingTimeSelection.value === 'CUSTOM'}"
                                        >
                                        </v-radio>
                                      </v-radio-group>
                                    </validated-component>
                                  </v-col>
                                </v-row>
                                <v-row class="mt-0" v-if="fleet.waitingTimeConfiguration.selectionType === 'CUSTOM'">
                                  <v-col cols="12" class="pt-0">
                                    <div class="d-flex flex-row align-center">
                                      <i18n
                                        path="fleetManagementForm.waitingTimeConfiguration.waitingTimeConfigurationThresholdLabel">
                                        <template v-slot:lineBreak>
                                          <br>
                                        </template>
                                        <template
                                          v-slot:waitingTimeConfigurationThresholdInput
                                        >
                                          <validated-component
                                            v-model="fleet.waitingTimeConfiguration.timeThreshold"
                                            :validator="$v.fleet.waitingTimeConfiguration.timeThreshold"
                                            name="timeThreshold"
                                            class="d-inline-block"
                                          >
                                            <v-text-field
                                              v-model.number="fleet.waitingTimeConfiguration.timeThreshold"
                                              outlined
                                              type="number"
                                              max="60"
                                              min="1"
                                              hide-details
                                              no-filter
                                              class="mx-1"
                                              dense
                                            />
                                          </validated-component>
                                        </template>
                                      </i18n>
                                    </div>
                                  </v-col>
                                </v-row>
                              </template>

                              <v-row
                                v-if="isWaitingTimeConfigurationEnabled"
                              >
                                <v-col cols="12">
                                  <v-btn
                                    color="error"
                                    outlined
                                    x-small
                                    @click="removeCustomWaitingTimeRule"
                                  >{{
                                      $t('fleetManagementForm.waitingTimeConfiguration.removeCustomWaitingTimeRule')
                                    }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <div v-if="!isWaitingTimeConfigurationEnabled">
                                <v-btn
                                  color="success"
                                  outlined
                                  x-small
                                  @click="addCustomWaitingTimeRule"
                                >{{
                                    $t('fleetManagementForm.waitingTimeConfiguration.addCustomWaitingTimeRule')
                                  }}
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- END: WAITING TIME CONFIGURATION -->

                  <!-- BEGIN: PRICING DISPLAY FOR PAYMENT METHODS CONFIGURATION -->
                  <v-row class="mb-6">
                    <v-col cols="12">
                      <v-card>
                        <v-card-title>
                          <h2>{{ $t('fleetManagementForm.pricingDisplayForPaymentMethodsConfiguration.label') }}</h2>
                        </v-card-title>
                        <hr class="mb-12">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" class="form-part">
                              <template v-if="isPricingDisplayForPaymentMethodsConfigurationEnabled">
                                <v-row class="mt-2 pb-0">
                                  <v-col cols="8">
                                    <div v-for="pricingDisplayForPaymentMethod in pricingDisplayForPaymentMethodSelections" :key="pricingDisplayForPaymentMethod.value">
                                      <validated-component
                                        name="selectionType"
                                        v-model="fleet.pricingDisplayForPaymentMethodsConfiguration[pricingDisplayForPaymentMethod.value].isEnabled"
                                        :validator="$v.fleet.pricingDisplayForPaymentMethodsConfiguration[pricingDisplayForPaymentMethod.value].isEnabled"
                                      >
                                        <v-checkbox
                                          v-model="fleet.pricingDisplayForPaymentMethodsConfiguration[pricingDisplayForPaymentMethod.value].isEnabled"
                                          :label=" pricingDisplayForPaymentMethod.text"
                                        >
                                        </v-checkbox>
                                      </validated-component>
                                    </div>
                                  </v-col>
                                </v-row>
                              </template>

                              <v-row
                                v-if="isPricingDisplayForPaymentMethodsConfigurationEnabled"
                              >
                                <v-col cols="12">
                                  <v-btn
                                    color="error"
                                    outlined
                                    x-small
                                    @click="removePricingDisplayForPaymentMethodsConfiguration"
                                  >{{
                                      $t('fleetManagementForm.pricingDisplayForPaymentMethodsConfiguration.removePricingDisplayConfigurationForPaymentMethods')
                                    }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <div v-if="!isPricingDisplayForPaymentMethodsConfigurationEnabled">
                                <v-btn
                                  color="success"
                                  outlined
                                  x-small
                                  @click="addPricingDisplayForPaymentMethodsConfiguration"
                                >{{
                                    $t('fleetManagementForm.pricingDisplayForPaymentMethodsConfiguration.addPricingDisplayConfigurationForPaymentMethods')
                                  }}
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- END: PRICING DISPLAY FOR PAYMENT METHODS CONFIGURATION -->

                  <v-row class="mb-6" v-if="fleet.driverBookingInfoDisplayConfiguration">
                    <v-col cols="12">
                      <v-card>
                        <v-card-title>
                          <h2>{{
                              $t('fleetManagementForm.driverBookingInfoDisplayConfiguration.label')
                            }}</h2>
                        </v-card-title>
                        <hr>
                        <v-card-text>
                          <v-row>
                            <v-col cols="6" class="form-part">
                              <validated-component
                                name="marketingProvider"
                                v-model="fleet.driverBookingInfoDisplayConfiguration.driverNameLabel"
                                :validator="$v.fleet.driverBookingInfoDisplayConfiguration.driverNameLabel">
                                <h4 class="mr-2">Driver Name Label</h4>
                                <v-select
                                  v-model="fleet.driverBookingInfoDisplayConfiguration.driverNameLabel"
                                  :items="driverNameLabels"
                                  item-text="text"
                                  item-value="value"
                                  outlined
                                  no-filter
                                  hide-details
                                ></v-select>
                              </validated-component>
                              <validated-component
                                :name="$t(`fleetManagementForm.driverBookingInfoDisplayConfiguration.showDriverReference.label`)"
                                v-model="fleet.driverBookingInfoDisplayConfiguration.showDriverReference"
                                :validator="$v.fleet.driverBookingInfoDisplayConfiguration.showDriverReference"
                                class="d-block">
                                <v-checkbox
                                  v-model="fleet.driverBookingInfoDisplayConfiguration.showDriverReference"
                                  :label="$t('fleetManagementForm.driverBookingInfoDisplayConfiguration.showDriverReference.label')"></v-checkbox>
                              </validated-component>
                              <validated-component
                                :name="$t(`fleetManagementForm.driverBookingInfoDisplayConfiguration.showDriverImage.label`)"
                                v-model="fleet.driverBookingInfoDisplayConfiguration.showDriverImage"
                                :validator="$v.fleet.driverBookingInfoDisplayConfiguration.showDriverImage"
                                class="d-block">
                                <v-checkbox
                                  v-model="fleet.driverBookingInfoDisplayConfiguration.showDriverImage"
                                  :label="$t('fleetManagementForm.driverBookingInfoDisplayConfiguration.showDriverImage.label')"></v-checkbox>
                              </validated-component>
                              <validated-component
                                :name="$t(`fleetManagementForm.driverBookingInfoDisplayConfiguration.showRegistrationPlate.label`)"
                                v-model="fleet.driverBookingInfoDisplayConfiguration.showRegistrationPlate"
                                :validator="$v.fleet.driverBookingInfoDisplayConfiguration.showRegistrationPlate"
                                class="d-block">
                                <v-checkbox
                                  v-model="fleet.driverBookingInfoDisplayConfiguration.showRegistrationPlate"
                                  :label="$t('fleetManagementForm.driverBookingInfoDisplayConfiguration.showRegistrationPlate.label')"></v-checkbox>
                              </validated-component>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row class="mb-6">
                    <v-col cols="12">
                      <v-card>
                        <v-card-title>
                          <h2>{{ $t('fleetManagementForm.reiceiptsConfiguration.label') }}</h2>
                        </v-card-title>
                        <hr>
                        <v-card-text>
                          <v-row>
                            <v-col cols="4" class="form-part justify-center align-center">
                              <validated-component
                                :name="$t(`fleetManagementForm.reiceiptsConfiguration.disableReceiptForPayments.cash`)"
                                v-model="fleet.receiptConfig.disableForPayments"
                                :validator="$v.fleet.receiptConfig.disableForPayments">
                                <v-checkbox
                                  v-model="fleet.receiptConfig.disableForPayments"
                                  :value="'cash'"
                                  :label="$t('fleetManagementForm.reiceiptsConfiguration.disableReceiptForPayments.cash')"></v-checkbox>
                              </validated-component>
                              <validated-component
                                :name="$t(`fleetManagementForm.reiceiptsConfiguration.showAdditionalFees`)"
                                v-model="fleet.receiptConfig.showAdditionalFees"
                                :validator="$v.fleet.receiptConfig.showAdditionalFees">
                                <v-checkbox
                                  v-model="fleet.receiptConfig.showAdditionalFees"
                                  :label="$t('fleetManagementForm.reiceiptsConfiguration.showAdditionalFees')"></v-checkbox>
                              </validated-component>
                              <validated-component
                                :name="$t(`fleetManagementForm.reiceiptsConfiguration.taxMessage`)"
                                v-model="fleet.receiptConfig.useDefaultTaxMessage"
                                :validator="$v.fleet.receiptConfig.useDefaultTaxMessage">
                                <v-checkbox
                                  v-model="fleet.receiptConfig.useDefaultTaxMessage"
                                  :label="$t('fleetManagementForm.reiceiptsConfiguration.taxMessage')"></v-checkbox>
                              </validated-component>
                              <validated-component
                                v-if="!fleet.receiptConfig.useDefaultTaxMessage"
                                name="customTaxesMessage"
                                v-model="fleet.receiptConfig.customTaxesMessage"
                                :validator="$v.fleet.receiptConfig.customTaxesMessage">
                                <v-text-field
                                  v-model="fleet.receiptConfig.customTaxesMessage"
                                  :label="$t(`fleetManagementForm.reiceiptsConfiguration.customTaxesMessage`)"
                                  :placeholder="$t('fleetManagementForm.reiceiptsConfiguration.customTaxesMessage')"
                                  outlined
                                  hide-details
                                  no-filter
                                ></v-text-field>
                              </validated-component>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="form-part">
                      <v-label>{{ $t('fleetManagementForm.marketing') }}</v-label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="form-part">
                      <validated-component
                        name="marketingProvider"
                        v-model="fleet.connectors.marketing.name"
                        :validator="$v.fleet.connectors.marketing.name">
                        <v-select
                          v-model="fleet.connectors.marketing.name"
                          :items="[
                'voucherify',
              ]"
                          :label="$t(`fleetManagementForm.marketingProvider`)"
                          :placeholder="$t('fleetManagementForm.marketingProvider')"
                          outlined
                          no-filter
                        ></v-select>
                      </validated-component>
                    </v-col>
                    <v-col cols="2" class="form-part">
                      <validated-component
                        name="marketingInstance"
                        v-model="fleet.connectors.marketing.configuration.instance"
                        :validator="$v.fleet.connectors.marketing.configuration.instance">
                        <v-select
                          v-model="fleet.connectors.marketing.configuration.instance"
                          :items="[
                'europe',
                'us',
                'asia'
              ]"
                          :label="$t(`fleetManagementForm.marketingInstance`)"
                          :placeholder="$t('fleetManagementForm.marketingInstance')"
                          outlined
                          no-filter
                        ></v-select>
                      </validated-component>
                    </v-col>
                    <v-col cols="4" class="form-part">
                      <validated-component
                        name="marketingProviderApplicationId"
                        v-model="fleet.connectors.marketing.configuration.applicationId"
                        :validator="$v.fleet.connectors.marketing.configuration.applicationId">
                        <v-text-field
                          v-model="fleet.connectors.marketing.configuration.applicationId"
                          :label="$t(`fleetManagementForm.marketingProviderApplicationId`)"
                          :placeholder="$t('fleetManagementForm.marketingProviderApplicationId')"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                    <v-col cols="4" class="form-part">
                      <validated-component
                        name="marketingProviderClientSecret"
                        v-model="fleet.connectors.marketing.configuration.clientSecret"
                        :validator="$v.fleet.connectors.marketing.configuration.clientSecret">
                        <v-text-field
                          v-model="fleet.connectors.marketing.configuration.clientSecret"
                          :label="$t(`fleetManagementForm.marketingProviderClientSecret`)"
                          :placeholder="$t('fleetManagementForm.marketingProviderClientSecret')"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="form-part">
                      <v-checkbox v-model="fleet.taxes.enabled"
                                  :label="$t(`fleetManagementForm.taxes`)">
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="fleet.taxes.enabled">
                    <v-col cols="6">
                      <validated-component
                        name="country"
                        v-model="fleet.taxes.country"
                        :validator="$v.fleet.taxes.country">
                        <v-select
                          v-model="fleet.taxes.country"
                          :items="countryCodes"
                          :label="$t(`fleetManagementForm.country`)"
                          :placeholder="$t('fleetManagementForm.country')"
                          outlined
                          hide-details
                          no-filter
                        ></v-select>
                      </validated-component>
                    </v-col>
                    <v-col cols="6">
                      <validated-component
                        name="region"
                        v-model="fleet.taxes.region"
                        :validator="$v.fleet.taxes.region">
                        <v-select
                          v-model="fleet.taxes.region"
                          :items="regions"
                          :label="$t(`fleetManagementForm.region`)"
                          :placeholder="$t('fleetManagementForm.region')"
                          outlined
                          hide-details
                          no-filter
                        ></v-select>
                      </validated-component>
                    </v-col>
                  </v-row>
                  <v-row v-if="fleet.taxes.enabled">
                    <v-col cols="4" v-for="(taxField, index) in countryTaxFields" :key="index">
                      <validated-component
                        :name="`${taxField}`"
                        v-model="fleet.taxes.numbers[`${taxField}`]"
                        :validator="$v.fleet.taxes.numbers[`${taxField}`]">
                        <v-text-field
                          v-model="fleet.taxes.numbers[`${taxField}`]"
                          :label="$t(`fleetManagementForm.${taxField}`)"
                          :placeholder="$t(`fleetManagementForm.${taxField}`)"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="form-part">
                      <h3>{{ $t('fleetManagementForm.network') }}</h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="latitude"
                        :validator="$v.fleet.networkCoordinates.latitude"
                        v-model="fleet.networkCoordinates.latitude">
                        <v-text-field
                          v-model="fleet.networkCoordinates.latitude"
                          :label="$t(`fleetManagementForm.latitude`)"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="longitude"
                        :validator="$v.fleet.networkCoordinates.longitude"
                        v-model="fleet.networkCoordinates.longitude">
                        <v-text-field
                          v-model="fleet.networkCoordinates.longitude"
                          :label="$t(`fleetManagementForm.longitude`)"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="networkRadius"
                        :validator="$v.fleet.networkRadius"
                        v-model="fleet.networkRadius">
                        <v-text-field
                          v-model="fleet.networkRadius"
                          :label="$t(`fleetManagementForm.networkRadius`)"
                          outlined
                          hide-details
                          no-filter
                        ></v-text-field>
                      </validated-component>
                    </v-col>
                    <v-col cols="3" class="form-part">
                      <v-text-field
                        v-model="networkRadiusKM"
                        outlined
                        hide-details
                        no-filter
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <network-polygon-map
                        v-if="this.pageSettings.isNetworkMapEnabled"
                        :latitude="fleet.networkCoordinates.latitude"
                        :longitude="fleet.networkCoordinates.longitude"
                        :radius="fleet.networkRadius"
                        @updateNetworkLatLong="setNetworkCoordinates"
                      ></network-polygon-map>
                      <v-row v-else class="mb-4">
                        <!-- By default the map is disabled because it interferes with scrolling up and down the page -->
                        <v-btn
                          @click="enableNetworkMap"
                          color="primary">
                          {{ $t('fleetManagementForm.fleetNetworkForm.showMapButton') }}
                        </v-btn>
                        <p><br/><br/></p>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="form-part">
                      <h3>{{ $t('fleetManagementForm.dispatch') }}</h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="form-part">
                      <validated-component
                        name="dispatchEngineCode"
                        v-model="fleet.dispatchEngineCode"
                        :validator="$v.fleet.dispatchEngineCode">
                        <v-radio-group v-model="fleet.dispatchEngineCode"
                                       @change="onDispatchEngineCodeChanged">
                          <template v-slot:label>
                            <div>{{ $t('fleetManagementForm.dispatchEngine') }}</div>
                          </template>
                          <v-radio
                            v-for="availableDispatchEngine in availableDispatchEngines"
                            :key="availableDispatchEngine.value"
                            :label=" availableDispatchEngine.text"
                            :value="availableDispatchEngine.value">
                          </v-radio>
                        </v-radio-group>

                      </validated-component>
                    </v-col>
                  </v-row>
                  <!-- Dispatch Engine Section-->
                  <template>
                    <v-row>
                      <v-col cols="12">
                        <validated-component
                          name="dispatchBaseURL"
                          v-model="fleet.credentials.dispatchAttributes.baseURL"
                          :validator="$v.fleet.credentials.dispatchAttributes.baseURL">
                          <v-text-field
                            v-model="fleet.credentials.dispatchAttributes.baseURL"
                            :label="$t(`fleetManagementForm.dispatchBaseURL`)"
                            :placeholder="$t('fleetManagementForm.dispatchBaseURL')"
                            outlined
                            hide-details
                            no-filter
                          ></v-text-field>
                        </validated-component>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4" class="form-part">
                        <validated-component
                          name="clientIx"
                          v-model="fleet.clientIx"
                          :validator="$v.fleet.clientIx">
                          <v-text-field
                            v-model="fleet.clientIx"
                            :label="$t(`fleetManagementForm.clientIx`)"
                            :placeholder="$t('fleetManagementForm.clientIx')"
                            outlined
                            hide-details
                            no-filter
                          ></v-text-field>
                        </validated-component>
                      </v-col>
                      <v-col cols="4" class="form-part">
                        <validated-component
                          name="coId"
                          v-model="fleet.coId"
                          :validator="$v.fleet.coId">
                          <v-text-field
                            v-model="fleet.coId"
                            :label="$t(`fleetManagementForm.coId`)"
                            :placeholder="$t('fleetManagementForm.coId')"
                            outlined
                            hide-details
                            no-filter
                            readonly
                          ></v-text-field>
                        </validated-component>
                      </v-col>
                      <v-col cols="4" class="form-part">
                        <validated-component
                          name="siteId"
                          v-model="fleet.siteId"
                          :validator="$v.fleet.siteId">
                          <v-text-field
                            v-model="fleet.siteId"
                            :label="$t(`fleetManagementForm.siteId`)"
                            :placeholder="$t('fleetManagementForm.siteId')"
                            outlined
                            hide-details
                            no-filter
                          ></v-text-field>
                        </validated-component>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5" class="form-part">
                        <validated-component
                          name="dispatchKey"
                          v-model="fleet.credentials.dispatchAttributes.apiCredentials.key"
                          :validator="$v.fleet.credentials.dispatchAttributes.apiCredentials.key">
                          <v-text-field
                            v-model="fleet.credentials.dispatchAttributes.apiCredentials.key"
                            :label="$t(`fleetManagementForm.dispatchKey`)"
                            :placeholder="$t('fleetManagementForm.dispatchKey')"
                            outlined
                            hide-details
                            no-filter
                          ></v-text-field>
                        </validated-component>
                      </v-col>
                      <v-col cols="5" class="form-part">
                        <validated-component
                          name="dispatchSecret"
                          v-model="fleet.credentials.dispatchAttributes.apiCredentials.secret"
                          :validator="$v.fleet.credentials.dispatchAttributes.apiCredentials.secret">
                          <v-text-field
                            v-model="fleet.credentials.dispatchAttributes.apiCredentials.secret"
                            :label="$t(`fleetManagementForm.dispatchSecret`)"
                            :placeholder="$t('fleetManagementForm.dispatchSecret')"
                            outlined
                            hide-details
                            no-filter
                          ></v-text-field>
                        </validated-component>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="form-part">
                        <v-label>{{ $t('fleetManagementForm.apiGatewayCredentials') }}</v-label>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="9" class="form-part">
                        <v-text-field
                          v-if="fleet.credentials.dispatchAttributes.gatewayApiCredentials"
                          :label="$t(`fleetManagementForm.apiGatewayToken`)"
                          :placeholder="$t('fleetManagementForm.apiGatewayToken')"
                          v-model="apiGatewayToken"
                          outlined
                          hide-details
                          no-filter
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" class="form-part">
                        <v-btn
                          @click.native="generateApiGatewayCredentials"
                          class="generate-api-gateway-credentials-btn"
                          outlined>
                          {{ $t('fleetManagementForm.regenerateApiGatewayCredentials') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <!-- End Dispatch Engine Section-->
                  <v-row>
                    <v-col cols="12" class="form-part">
                      <v-label>{{ $t('fleetManagementForm.preauthorizationRules') }}</v-label>
                      <v-switch
                        @change="onPreauthorizationRulesUpdate"
                        :label="$t(`fleetManagementForm.useCustomPreauthorizationRules`)"
                        v-model="useCustomPreauthorizationRules">
                      </v-switch>
                    </v-col>
                  </v-row>
                  <v-row v-if="useCustomPreauthorizationRules">
                    <v-col cols="12">
                      <v-jsoneditor
                        :plus="false"
                        height="400px"
                        v-model="fleet.preauthorizationRules"
                        :validator="$v.fleet.preauthorizationRules">
                        >
                      </v-jsoneditor>
                      <p class="help-json-label">
                        {{ $t('fleetManagementForm.preauthorizationRulesHelp') }}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <h4 class="blue--text">Allowed Payment Methods</h4>
                    </v-col>
                    <v-col cols="12">
                      <validated-component
                        v-model="fleet.allowedPaymentMethods"
                        :validator="$v.fleet.allowedPaymentMethods">
                        <payment-methods-form ref="paymentMethodsForm"
                                              v-model="fleet.allowedPaymentMethods"></payment-methods-form>
                      </validated-component>
                    </v-col>
                  </v-row>
                  <v-row class="justify-end">
                    <v-btn
                      @click="save"
                      :disabled="$v.$invalid"
                      color="primary">
                      {{ $t('save') }}
                    </v-btn>
                    <!--                    <v-btn-->
                    <!--                      @click="$v.$touch()"-->
                    <!--                      color="primary">-->
                    <!--                      Touch-->
                    <!--                    </v-btn>-->
                  </v-row>
                </v-col>
                <template>
                  <v-divider></v-divider>
                  <v-col cols="12" class="py-14 px-8">
                    <v-row>
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Fleet form validation
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div class="json-preview">
                              <json-viewer
                                  :value="fleetErrors"
                                  :expand-depth=5
                                  copyable
                                  boxed
                                  sort></json-viewer>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-row>
                  </v-col>
                </template>

              </v-col>
            </v-tab-item>
            <!-- END: fleet-tab -->

            <v-tab-item :value="'fleetBookingAppConfig'">
              <fleet-booking-app-config :fleet="fleet"></fleet-booking-app-config>
            </v-tab-item>
          </v-tabs-items>

        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import crypto from 'crypto';
import _ from 'lodash';
import { DispatchEngine } from '@/core/dispatchEngine';
import { getCodes } from 'country-list';
import countryRegions from 'country-region-data';
import environment from '@/environment';
import PaymentMethodsForm from '@/components/PaymentMethodsForm.vue';
import ManagedFeesForm from '@/components/ManagedFeesForm.vue';
import NetworkPolygonMap from '@/components/fleetManagement/NetworkPolygonMap.vue';
// import ObjectUtil from '@/util/ObjectUtil';
import FleetBookingAppConfig
  from '@/components/fleetManagement/FleetBookingAppConfig/FleetBookingAppConfig.vue';
import {
  FleetManagementFormDelegate,
} from '@/components/fleetManagement/FleetManagementFormDelegate';
import { WaitingTimeTypes } from '@/core/waitingTimeTypes';
import {
  fleetValidation,
  ic2FleetPropertiesValidation,
  ic2DispatchAttributesValidation,
} from '../validationDatas/fleetValidation';

const ic2EngineSyncOptions = [
  'ic2',
  'none',
];

export default {
  name: 'fleet-management-form',
  components: {
    PaymentMethodsForm,
    NetworkPolygonMap,
    ManagedFeesForm,
    FleetBookingAppConfig,
  },
  props: {
    fleet: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isUpdateForm: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 'fleet', // allowed values at this moment ['fleet', 'fleetBookingAppConfig']
      selectableSyncOptions: ic2EngineSyncOptions,
      useCustomPreauthorizationRules: false,
      selectedPaymentConnectorName: null,
      paymentConnectorNamesDisableRemoval: [],
      localFleetModel: {
        waitingTimeConfiguration: {
          isEnabled: false,
        },
        pricingDisplayForPaymentMethodsConfiguration: {
          cash: {
            isEnabled: true,
          },
          businessDispatchInvoice: {
            isEnabled: true,
          },
        },
        fees: {
          prematureCancellationFee: {
            exemptDriverAssignmentDurationConfig: {
              isEnabled: true,
            },
            chargeablePostDriverAssignedDurationConfig: {
              isEnabled: true,
            },
          },
          noShowFee: {
            requiredDriverWaitingDurationAfterArrivalConfig: {
              isEnabled: false,
            },
            exemptAsapDriverArrivalDurationConfig: {
              isEnabled: false,
            },
            preBookingMaxPickupToArrivalDeltaConfig: {
              isEnabled: false,
            },
          },
        },
      },
      paymentInstanceMapping: {
        stripe: 'canada',
        'stripe-ie': 'ireland',
        'stripe-uk': 'uk',
        'stripe-us': 'us',
        'stripe-aus': 'aus',
        'stripe-uk-test': 'uk',
      },
      dataRegions: [{
        display: 'Europe',
        code: 'eu',
      }, {
        display: 'North America',
        code: 'na',
      }, {
        display: 'Australia',
        code: 'au',
      }],
      taxFields: {
        default: [],
        CA: {
          QC: ['gst', 'qst', 'pst'],
        },
      },
      availableDispatchEngines: [
        {
          value: DispatchEngine.IC2,
          text: 'IC2',
        },
        {
          value: DispatchEngine.MK,
          text: 'Mobile Knowledge',
        },
      ],
      pageSettings: {
        isNetworkMapEnabled: false,
      },
      driverNameLabels: [
        {
          text: 'None',
          value: 'NONE',
        },
        {
          text: 'First Name',
          value: 'FIRST_NAME',
        },
        {
          text: 'Aka',
          value: 'AKA_FIELD',
        },
        {
          text: 'Full Name',
          value: 'FULL_NAME',
        },
      ],
      pricingDisplayForPaymentMethodSelections: [
        {
          text: 'Show Fare Estimate & Pricing Data for CASH Payment Method',
          value: 'cash',
        },
        {
          text: 'Show Fare Estimate & Pricing Data for Business Invoice Payment Method',
          value: 'businessDispatchInvoice',
        },
      ],
      availableWaitingTimeSelections: [
        {
          text: 'Always Use Short Waiting Time',
          value: WaitingTimeTypes.SHORT,
        },
        {
          text: 'Always Use Long Waiting Time',
          value: WaitingTimeTypes.LONG,
        },
        {
          text: 'Lock to Specific Time',
          value: WaitingTimeTypes.CUSTOM,
        },
      ],
    };
  },
  watch: {
    // This is a "shallow" watch of the fleet object, meaning that any changes
    // inside the form will NOT trigger this watcher, since we do not change
    // object reference.
    // We expect that this watcher gets triggered every time the parent component
    // changes the entire fleet object.
    fleet() {
      this.$nextTick(() => {
        this.resetSyncOptions();
        this.resetPaymentConnectorRemovalOption();
        this.resetPreauthorizationRuleStrategy();
        this.initLocalFleetModel();
        this.$v.$reset();
      });
    },
    'localFleetModel.fees.noShowFee': {
      deep: true,
      handler() {
        this.$v.$touch();
      },
    },
  },
  validations() {
    const validationRules = _.cloneDeep(fleetValidation);
    delete validationRules.fleet.dispatchInformation;
    validationRules.fleet.credentials.dispatchAttributes = ic2DispatchAttributesValidation;
    validationRules.fleet = _.merge(validationRules.fleet, ic2FleetPropertiesValidation);
    return validationRules;
  },
  computed: {
    paymentConnectorNames() {
      return _.map(this.fleet.connectors.payment, 'name');
    },
    availablePaymentConnectorNames() {
      const availablePaymentConnector = [
        'stripe',
        'stripe-ie',
        'stripe-uk',
        'stripe-us',
        'stripe-aus',
        'stripe-uk-test',
      ];
      return _.filter(
        availablePaymentConnector,
        connector => !this.paymentConnectorNames.includes(connector),
      );
    },
    countryCodes() {
      return getCodes();
    },
    hasNetworkAssociated() {
      return _.get(this.fleet, 'fleetNetworks.length');
    },
    countryTaxFields() {
      const countryFields = _.get(this.taxFields, `${this.fleet.taxes.country}.default`, this.taxFields.default);
      const regionFields = _.get(this.taxFields, `${this.fleet.taxes.country}.${this.fleet.taxes.region}`, null);
      return regionFields || countryFields;
    },
    regions() {
      if (this.fleet.taxes.country) {
        const regionData = countryRegions
          .find(regions => regions.countryShortCode === this.fleet.taxes.country);
        if (regionData) {
          return regionData.regions.map(region => region.shortCode);
        }
      }
      return [];
    },
    apiGatewayToken() {
      if (this.fleet.credentials.dispatchAttributes.gatewayApiCredentials
        && this.fleet.credentials.dispatchAttributes.gatewayApiCredentials.username
      ) {
        return Buffer
          .from(`${this.fleet.credentials.dispatchAttributes.gatewayApiCredentials.username}:${this.fleet.credentials.dispatchAttributes.gatewayApiCredentials.password}`)
          .toString('base64');
      }
      return null;
    },
    isMobileKnowledgeDispatchEngineSelected() {
      return this.fleet.dispatchEngineCode === DispatchEngine.MK;
    },
    availableTimezones() {
      return this.$moment.tz.names();
    },
    networkRadiusKM() {
      if (this.fleet.networkRadius) {
        return `${this.fleet.networkRadius / 1000} KM radius`;
      }
      return '0 KM radius';
    },
    isPrematureCancellationFeeRuleSet() {
      return (this.fleet.fees.prematureCancellationFee.cancellationFee)
        || (this.fleet.fees.prematureCancellationFee.driverShare)
        || (this.fleet.fees.prematureCancellationFee.chargeablePostDriverAssignedDurationMinutes)
        || (this.fleet.fees.prematureCancellationFee.exemptDriverAssignmentDurationMinutes);
    },
    isPassengerNoShowFeeRuleSet() {
      // @TODO - need to refactor the whole shebang
      const config = this.fleet.fees.passengerNoShowFeeConfig;
      return config && (config?.noShowFee !== null) && (config?.noShowFee !== undefined);
    },
    isWaitingTimeConfigurationEnabled() {
      const config = this.fleet.waitingTimeConfiguration;
      return config && (config?.isEnabled !== null) && (config?.isEnabled !== undefined) && (config.isEnabled !== false);
    },
    isPricingDisplayForPaymentMethodsConfigurationEnabled() {
      return !_.isNil(this.fleet.pricingDisplayForPaymentMethodsConfiguration);
    },
    isNoShowFeePrebookingMaxPickupToArrivalDeltaEnabled: {
      get() {
        return (!_.isNil(this.fleet.fees.passengerNoShowFeeConfig)
          && !_.isNil(this.fleet.fees.passengerNoShowFeeConfig?.prebookingMaxPickupToArrivalDeltaMinutes)
        );
      },
      set(isChecked) {
        if (isChecked) {
          this.fleet.fees.passengerNoShowFeeConfig.prebookingMaxPickupToArrivalDeltaMinutes = 1;
        } else {
          this.fleet.fees.passengerNoShowFeeConfig.prebookingMaxPickupToArrivalDeltaMinutes = null;
        }
      },
    },
    fleetErrors() {
      return this.$v;
    },
  },
  methods: {
    addConnector() {
      this.fleet.connectors.payment.push({
        name: this.selectedPaymentConnectorName,
        instance: this.paymentInstanceMapping[`${this.selectedPaymentConnectorName}`],
        configuration: {
          fees: {},
        },
      });
    },
    resetPaymentConnectorRemovalOption() {
      this.paymentConnectorNamesDisableRemoval = _.map(this.fleet.connectors.payment, 'name');
    },
    removePaymentConnector(index) {
      this.fleet.connectors.payment.splice(index, 1);
      this.fleet = { ...this.fleet };
    },
    resetSyncOptions() {
      this.selectableSyncOptions = ic2EngineSyncOptions.slice();
      // eslint-disable-next-line prefer-destructuring
      this.fleet.syncMode = this.selectableSyncOptions[0];
    },
    resetPreauthorizationRuleStrategy() {
      this.useCustomPreauthorizationRules = (this.fleet.preauthorizationRuleStrategy === 'custom');
    },
    onDispatchEngineCodeChanged() {
      this.methodChangeDispatchAttributesForDispatchEngine();
      this.resetSyncOptions();
    },
    onPreauthorizationRulesUpdate(newValue) {
      this.fleet.preauthorizationRuleStrategy = newValue ? 'custom' : 'default';
    },
    methodChangeDispatchAttributesForDispatchEngine() {
      this.fleet.credentials.dispatchAttributes.dispatchId = DispatchEngine.IC2.toLowerCase();
    },
    generateApiGatewayCredentials() {
      this.$set(this.fleet.credentials.dispatchAttributes, 'gatewayApiCredentials', {
        username: crypto.randomBytes(32)
          .toString('base64'),
        password: crypto.randomBytes(64)
          .toString('base64'),
      });
    },
    async save() {
      let fleet = _.cloneDeep(this.fleet);
      fleet.ivrPhoneNumber = fleet.supportPhoneNumber;
      fleet = this.handleFleetFees({ fleet });
      // eslint-disable-next-line no-constant-condition
      fleet = this.handleDriverBookingInfoDisplayConfiguration({ fleet });
      fleet = this.handlePricingDisplayForPaymentMethodsConfiguration({ fleet });
      if (!this.isDispatchUrlAllowed(this.fleet.credentials.dispatchAttributes.baseURL)) {
        // Display error
        await this.$store.dispatch(
          'globalMessages/addError',
          { message: 'fleetManagementForm.stagingUrlNotAllowed' },
        );
      } else {
        this.$emit('save', fleet);
      }
    },
    isDispatchUrlAllowed(url) {
      const keyWord = 'staging';
      if (url && environment.isDeploymentEnvProduction()) {
        // Make sure the keyWord is not in the url.
        return !url.includes(keyWord);
      }

      return true;
    },
    setNetworkCoordinates(event) {
      this.fleet.networkCoordinates = {
        latitude: event.latitude,
        longitude: event.longitude,
      };
    },
    enableNetworkMap() {
      this.pageSettings.isNetworkMapEnabled = true;
    },
    handleFleetFees({ fleet }) {
      const fleetData = _.cloneDeep(fleet);

      _.set(fleetData, 'fees.prematureCancellationFee', FleetManagementFormDelegate.transformPrematureCancellationFeeModel({
        formDataFleet: fleet,
        localFleetModel: this.localFleetModel,
      }));

      _.set(fleetData, 'fees.passengerNoShowFeeConfig', FleetManagementFormDelegate.transformNoShowFeeModel({
        formDataFleet: fleet,
        localFleetModel: this.localFleetModel,
      }));

      // // Delete the falsy (undefined/null/zero) values
      // fleetData = ObjectUtil.deleteKeys(fleetData, [
      //   'fees',
      //
      //   'fees.prematureCancellationFee',
      //   'fees.prematureCancellationFee.cancellationFee',
      //   'fees.prematureCancellationFee.driverShare',
      //   'fees.prematureCancellationFee.chargeablePostDriverAssignedDurationSec',
      //   'fees.prematureCancellationFee.exemptDriverAssignmentDurationSec',
      //
      //   'fees.passengerNoShowFee',
      //   'fees.passengerNoShowFeeConfig',
      //   'fees.passengerNoShowFeeConfig.exemptAsapDriverArrivalDurationSec',
      //   'fees.passengerNoShowFeeConfig.requiredDriverWaitingDurationAfterArrivalSec',
      //   'fees.passengerNoShowFeeConfig.prebookingMaxPickupToArrivalDeltaSec',
      // ], value => !value);

      // fleetData.fees = _.omitBy(fleetData.fees, property => _.isObject(property) && Object.keys(property).length === 0);
      return fleetData;
    },
    addPrematureCancellationFeeRule() {
      this.fleet.fees.prematureCancellationFee = {
        cancellationFee: 2,
        driverShare: 2,
        chargeablePostDriverAssignedDurationMinutes: 5,
        exemptDriverAssignmentDurationMinutes: 15,
      };
    },
    removePrematureCancellationFeeRule() {
      this.fleet.fees.prematureCancellationFee = {
        cancellationFee: null,
        driverShare: null,
        chargeablePostDriverAssignedDurationMinutes: null,
        exemptDriverAssignmentDurationMinutes: null,
        requiredDriverWaitingDurationAfterArrivalSec: null,
      };
    },
    addPassengerNoShowFeeRule() {
      this.fleet.fees.passengerNoShowFeeConfig = {
        noShowFee: 2,
        driverShare: 0,
        exemptAsapDriverArrivalDurationMinutes: null,
        prebookingMaxPickupToArrivalDeltaMinutes: null,
        requiredDriverWaitingDurationAfterArrivalMinutes: null,
      };
    },
    removePassengerNoShowFeeRule() {
      this.fleet.fees.passengerNoShowFee = null;
      this.localFleetModel.fees.noShowFee.requiredDriverWaitingDurationAfterArrivalConfig.isEnabled = false;
      this.localFleetModel.fees.noShowFee.exemptAsapDriverArrivalDurationConfig.isEnabled = false;
      this.localFleetModel.fees.noShowFee.preBookingMaxPickupToArrivalDeltaConfig.isEnabled = false;
      this.fleet.fees.passengerNoShowFeeConfig = {
        noShowFee: null,
        driverShare: null,
        exemptAsapDriverArrivalDurationMinutes: null,
        prebookingMaxPickupToArrivalDeltaMinutes: null,
        requiredDriverWaitingDurationAfterArrivalMinutes: null,
      };
    },
    addCustomWaitingTimeRule() {
      this.fleet.waitingTimeConfiguration = {
        isEnabled: true,
        selectionType: null,
        timeThreshold: null,
      };
    },
    removeCustomWaitingTimeRule() {
      this.fleet.waitingTimeConfiguration = {
        isEnabled: false,
        selectionType: null,
        timeThreshold: null,
      };
    },
    addPricingDisplayForPaymentMethodsConfiguration() {
      this.fleet.pricingDisplayForPaymentMethodsConfiguration = {
        cash: {
          isEnabled: true,
        },
        businessDispatchInvoice: {
          isEnabled: true,
        },
      };
    },
    removePricingDisplayForPaymentMethodsConfiguration() {
      this.fleet.pricingDisplayForPaymentMethodsConfiguration = null;
    },
    handleDriverBookingInfoDisplayConfiguration({ fleet }) {
      const fleetData = _.cloneDeep(fleet);

      // converting minutes to seconds
      _.set(fleetData, 'driverBookingInfoDisplayConfiguration', {
        showDriverReference: fleetData.driverBookingInfoDisplayConfiguration.showDriverReference,
        driverNameLabel: fleetData.driverBookingInfoDisplayConfiguration.driverNameLabel,
        showDriverImage: fleetData.driverBookingInfoDisplayConfiguration.showDriverImage,
        showRegistrationPlate: fleetData.driverBookingInfoDisplayConfiguration.showRegistrationPlate,
      });

      return fleetData;
    },
    handlePricingDisplayForPaymentMethodsConfiguration({ fleet }) {
      const fleetData = _.cloneDeep(fleet);

      if (_.isNil(fleetData.pricingDisplayForPaymentMethodsConfiguration)) {
        delete fleetData.pricingDisplayForPaymentMethodsConfiguration;
      }

      return fleetData;
    },
    initLocalFleetModel() {
      // Initialise the local fleet model configuration settings

      // Premature Cancellation Fee Settings
      // Just commented out for now in case requirements change:
      // if (this.fleet?.fees?.prematureCancellationFee?.chargeablePostDriverAssignedDurationMinutes > 0) {
      //   this.localFleetModel.fees.prematureCancellationFee.chargeablePostDriverAssignedDurationConfig.isEnabled = true;
      // }

      // if (this.fleet?.fees?.prematureCancellationFee?.exemptDriverAssignmentDurationMinutes > 0) {
      //   this.localFleetModel.fees.prematureCancellationFee.exemptDriverAssignmentDurationConfig.isEnabled = true;
      // }

      // No Show Cancellation Fees Settings
      if (this.fleet?.fees?.passengerNoShowFeeConfig?.exemptAsapDriverArrivalDurationMinutes > 0) {
        this.localFleetModel.fees.noShowFee.exemptAsapDriverArrivalDurationConfig.isEnabled = true;
      }

      if (this.fleet?.fees?.passengerNoShowFeeConfig?.requiredDriverWaitingDurationAfterArrivalMinutes > 0) {
        this.localFleetModel.fees.noShowFee.requiredDriverWaitingDurationAfterArrivalConfig.isEnabled = true;
      }

      if (this.fleet?.fees?.passengerNoShowFeeConfig?.prebookingMaxPickupToArrivalDeltaMinutes > 0) {
        this.localFleetModel.fees.noShowFee.preBookingMaxPickupToArrivalDeltaConfig.isEnabled = true;
      }

      if (this.fleet?.waitingTimeConfiguration?.isEnabled) {
        this.localFleetModel.waitingTimeConfiguration.isEnabled = true;
      }
      if (this.fleet?.pricingDisplayForPaymentMethodsConfiguration) {
        this.localFleetModel.pricingDisplayForPaymentMethodsConfiguration.cash.isEnabled = this.fleet.pricingDisplayForPaymentMethodsConfiguration.cash.isEnabled;
        this.localFleetModel.pricingDisplayForPaymentMethodsConfiguration.businessDispatchInvoice.isEnabled = this.fleet.pricingDisplayForPaymentMethodsConfiguration.businessDispatchInvoice.isEnabled;
      }
    },
  },
};
</script>

<style scoped>
.form-part {
  padding-top: 0;
}

.generate-api-gateway-credentials-btn {
  height: 100% !important;
}

.help-json-label {
  font-size: 10px;
}

.dispatch-information-label {
  margin-top: 25px;
}

.payment-connector {
  padding-top: 20px;
  margin-bottom: 10px;
}

.form-part {
  padding-top: 0;
}

.form-section-divider {
  margin-bottom: 24px;
}

.generate-api-gateway-credentials-btn {
  height: 100% !important;
}

.mark-payment-account-migrated-btn {
  height: 100% !important;
}

.help-json-label {
  font-size: 10px;
}

.dispatch-information-label {
  margin-top: 25px;
}

.payment-connector {
  padding-top: 20px;
  margin-bottom: 10px;
}

.json-preview {
  overflow: auto;
  max-height: 600px;
  font-size: 12px;
}
</style>
